import React from 'react';
import { withRouter } from "react-router-dom";

import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { faTwitterSquare }  from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons/faEnvelopeSquare';
import { faFileExport }     from '@fortawesome/free-solid-svg-icons/faFileExport';
import { faListOl }         from '@fortawesome/free-solid-svg-icons/faListOl';
import { faPaste }          from '@fortawesome/free-solid-svg-icons/faPaste';
import { faSpinner }        from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faUndo }           from '@fortawesome/free-solid-svg-icons/faUndo';

import "@saeris/typeface-beleren-bold"

// import CSS first so other classes can override styles
import './App.css';

import KoFiButton    from './components/widgets/KoFiButton';
import PatreonButton from './components/widgets/PatreonButton';
import SetInputGroup from './components/widgets/ScryfallSetInputGroup';

import CardFilter                from './services/card-filters';
import CardPool, {
  SetCardPool, 
  TierListCardPool }             from './services/card-pool';
import PoolReader                from './services/pool-reader';
import { 
  BrowserRemoteStorage, 
  RemoteDeckFileID,
  RemotePoolStateID,
  RemoteTierListID,
  SeventeenLandsPoolID,
  SeventeenLandsTierListID,
  SourceID
}  from './services/remote-storage'
import SeventeenLands, { 
  SESSION_STORAGE_SEVENTEENLANDS_TIERLIST_PREFIX 
} from './services/seventeen-lands';

import CardPoolController, {
  FullSetController, 
  TierListController } from './views/CardPoolController';
import CardSetTech     from './views/CardSetTech';
import Filters         from "./views/Filters";
import { 
  AboutModal, 
  ExportModal, 
  ModalStatus, 
  PasteModal 
} from './views/Modal';

import {
  cardsToColumnsByColorIdentity,
  cardsToTierListColumns,
  getColorsSortedByPips,
  spreadCardsAcrossColumns
} from './helpers/card-pool.helpers';
import { getColorElement } from './helpers/ui.helpers';

import { RATING_TIER_PROPERTY } from './constants';

const RemoteStorage = new BrowserRemoteStorage();

/**
 * Prefixes used for storing full set and tier list card pools in session storage.
 * 
 * See `extractSessionId` and `maybeClearSetsFromLocalPoolState`
 */
const SESSION_STORAGE_SET_PREFIX = 'sets-';
const SESSION_STORAGE_TIERLISTS_PREFIX = 'tierlists-';

class Status {
  static Empty = new Status('Empty');
  static Loading = new Status('Loading');
  static Unpublished = new Status('Unpublished');
  static Published = new Status('Published');
  static Changed = new Status('Changed');
  static Publishing = new Status('Publishing');
  static Error = new Status('Error');

  static fromJSON(statusJson) {
    if (Status.Empty.matches(statusJson)) {
      return Status.Empty;
    } else if (Status.Loading.matches(statusJson)) {
      return Status.Loading;
    } else if (Status.Unpublished.matches(statusJson)) {
      return Status.Unpublished;
    } else if (Status.Published.matches(statusJson)) {
      return Status.Published;
    } else if (Status.Changed.matches(statusJson)) {
      return Status.Changed;
    } else if (Status.Publishing.matches(statusJson)) {
      return Status.Publishing;
    } else if (Status.Error.matches(statusJson)) {
      return Status.Error;
    }
  }

  constructor(status) {
    this.status = status;
  }
  
  /**
   * This checks status equality by internal status name since restoring 
   * status from saved pool state will not restore the same static objects.
   */
  matches(otherStatus) {
    return (this.status === otherStatus.status);
  }

  toString() {
    return `Status.${this.status}`;
  }
}

/**
 * Main App for sealed-deck-tech
 */
class App extends React.Component {

  constructor(props) {
    super(props);

    // initialize the default empty state
    this.cardpool = new CardPool();
    this.state = this.cardpool.toJsonState();

    this.state.cardfilters = [];
    this.state.isFilterAllActive = false;

    this.state.status = Status.Empty;
    this.state.modalStatus = ModalStatus.Closed;
  }

  componentDidMount() {
    // Register for the popstate event since we are using window.state.pushState,
    // otherwise pages will not refresh when the Back button is pushed;
    // (this is needed to support `history.push` used in `handlePaste`)
    // Don't care about the popped event, just trigger full load of the new app state
    window.onpopstate = this.popState;

    // attempt to load the pool state
    this.loadState();
  }

  componentWillUnmount() {
    // this may not be necessary, but shouldn't hurt
    window.onpopstate = () => {}
  }
  
  refreshDocumentTitle() {
    const urlParams = this.extractUrlParams();
    let newTitle = "SealedDeck.Tech";

    // Title for full set displays
    if (urlParams.set_code) {
      newTitle += ` [${urlParams.set_code.toUpperCase()}]`
    }
    // Title for Tier List card pools
    else if (urlParams.tier_list_set_code || urlParams.tier_list_pool_id) {
      if (this.cardpool.set) {
        newTitle += ` [${this.cardpool.set.toUpperCase()}]`
      }
      newTitle += ' - Tier List';
      if (urlParams.tier_list_pool_id) {
        newTitle += ` ${urlParams.tier_list_pool_id}`;
      }
    }
    // Title for 17Lands integrations
    else if (urlParams.seventeenlands_id) {
      newTitle += " - "
      if (urlParams.deck_id) {
        const deckNumber = parseInt(urlParams.deck_id) + 1;
        newTitle += `Deck #${deckNumber} @ `;
      }
      newTitle += `17Lands ${urlParams.seventeenlands_id}`
    }
    else if (urlParams.seventeenlands_tier_list_id) {
      newTitle += ` - 17Lands Tier List ${urlParams.seventeenlands_tier_list_id}`
    }

    // Always append a pool ID
    if (urlParams.pool_id) {
      newTitle += ` - ${urlParams.pool_id}`
    }
    document.title = newTitle;
  }

  /**
   * Reset `this.state` to the default initialized state and specified Status
   */
   resetState(status = Status.Empty) {
    this.setState({
      // reset to the default initialized state
      ...CardPool.emptyState(),
      cardfilters: [],
      isFilterAllActive: false,
      // optional source identifier for the current card pool
      currentId: undefined,
      // set the provided status (default Empty)
      status: status
    });
  }

  isEmpty = () => Status.Empty.matches(this.state.status);

  isLoading = () => Status.Loading.matches(this.state.status);

  isPublished = () => Status.Published.matches(this.state.status);

  isChanged = () => Status.Changed.matches(this.state.status);

  isPublishing = () => Status.Publishing.matches(this.state.status);

  isDisabled = () => (this.isLoading() || this.isPublishing());

  isError = () => {
    const invalidURL = !this.props.match.isExact
    return invalidURL || Status.Error.matches(this.state.status);
  }

  render() {
    // always referesh the document title when rendering; otherwise it is 
    // not certain that the url parameters have been updated in `this.props.match`
    this.refreshDocumentTitle();

    return (
      <React.Fragment>
        {this.maybeRenderModal()}
        {this.renderSidebar()}
        <span role="presentation" className="Resizer vertical disabled"></span>
        {this.renderCardPool()}
      </React.Fragment>
    );
  }

  renderCardPool() {
    // determine which type of card pool controller to initialize (based on the route)
    const urlParams = this.extractUrlParams();
    let ControllerType = CardPoolController;
    let setCode = undefined;
    if (this.isSetCardPool()) {
      setCode = urlParams.set_code;
      ControllerType = FullSetController
    }
    else if (this.isTierListCardPool()) {
      setCode = this.cardpool.set;
      ControllerType = TierListController
    }

    return <>
      <ControllerType
        disabled={this.isDisabled() || this.isError()} 
        error={this.isError()} 
        //Note: setCode is only used by FullSetController
        setCode={setCode}

        // pass in view items from the state, not the cardpool
        sideboard={this.state.sideboard}
        hidden={this.state.hidden}
        deck={this.state.deck}
        // also provide the cardpool for controller actions
        cardpool={this.cardpool}
        cardfilters={this.state.cardfilters}
        isFilterAllActive={this.state.isFilterAllActive}
        refreshViewState={this.onCardPoolChanged}
      />
    </>;
  }

  maybeRenderModal = () => {
    if (ModalStatus.About.matches(this.state.modalStatus)) {
      return <>
        <AboutModal 
          className="modal-about"
          visible={true} 
          onClose={this.closeModal}>
        </AboutModal>
      </>
    }
    else if (ModalStatus.Paste.matches(this.state.modalStatus)) {
      // don't allow paste-updates if this is a SetCardPool
      const disableUpdate = this.isSetCardPool() || this.isTierListCardPool();
      return <>
        <PasteModal 
          className="modal-paste"
          visible={true} 
          disableUpdate={disableUpdate}
          onClose={this.closeModal}
          handlePaste={this.handlePaste}
          handleUpdate={this.handleUpdate}>
        </PasteModal>
      </>
    }
    else if (ModalStatus.Export.matches(this.state.modalStatus)) {
      // declare the function to be called (instead of directly passing in 
      // `this.cardpool.exportCardCounts` because of scoping)
      const exportCardCountsFn = (includeSet, includeCollectorNo, combineHiddenSideboard) => 
        this.cardpool.exportCardCounts(includeSet, includeCollectorNo, combineHiddenSideboard);
      return <>
        <ExportModal 
          className="modal-export"
          visible={true} 
          onClose={this.closeModal}
          cardCountsFn={exportCardCountsFn}>
        </ExportModal>
      </>
    }
  }

  closeModal = () => this.setState({ modalStatus: ModalStatus.Closed })

  showPasteModal = () => this.setState({ modalStatus: ModalStatus.Paste })

  showExportModal = () => this.setState({ modalStatus: ModalStatus.Export })

  showAboutModal = () => this.setState({ modalStatus: ModalStatus.About })

  renderSidebar() {
    const allCards = this.cardpool.allCards();
    return (
      <div className="sidebar">
        <div className="main">
          <a className="title" href="/">SealedDeck.Tech</a>
          <input id="file" name="file" className="inputfile" 
            type="file" 
            accept=".txt" 
            disabled={this.isDisabled()} 
            onChange={this.handleFile} />
          { this.isLoading() ? 
              // show the disabled spinner button when isLoading
              <button id="loading" name="loading" className="loading" disabled={true}>
                <FontAwesomeIcon icon={faSpinner} spin />
              </button> :
              // otherwise show the normal file input label styled as a button
              <label htmlFor="file" className="loading button">Load Deck .txt</label>
          }
          <div>
            <button id="paste" name="paste" className="paste" 
                disabled={this.isDisabled()}
                onClick={this.showPasteModal}
                title="Paste Deck from Clipboard">
              <FontAwesomeIcon icon={faPaste} /> Paste Deck
            </button>
          </div>
          <SetInputGroup disabled={this.isDisabled()} />
          <div>
            <button id="revert" name="revert" className="revert"
                disabled={!this.isChanged() || this.isDisabled() || this.isError()} 
                onClick={this.revertState}
                title="Reload Pool (undo all changes)">
              <FontAwesomeIcon icon={faUndo} />
            </button>
            <button id="publish" name="publish"
                className={`publish ${this.isPublishing() ? "publishing" : ""}`}
                disabled={this.isEmpty() || this.isPublished() || this.isDisabled() || this.isError()} 
                onClick={this.handlePublish}>
              <FontAwesomeIcon className="spinner" icon={faSpinner} spin />
              <div className="text">Publish</div>
            </button>
          </div>
          <div>
            <button id="export-arena" name="export-arena" className="export-arena" 
                disabled={this.isDisabled() || this.isError()}
                onClick={this.showExportModal}
                title="Export for Arena">
              <FontAwesomeIcon icon={faFileExport} /> Export for Arena
            </button>
          </div>
          {this.renderRecentPools()}
        </div>
        <div className="controlpanel">
          <Filters 
              disabled={this.isDisabled() || this.isError()}
              cards={allCards} 
              cardfilters={this.state.cardfilters}
              addFilter={this.addFilter}
              removeFilter={this.removeFilter}
              // the secondary filter can be either 'Deck' or 'Tier List'
              secondaryName={this.isTierListCardPool() ? "Tier List" : "Deck"}
              isSecondaryFilterActive={this.state.isFilterAllActive}
              activateSecondaryFilter={this.activateFilterAll}
          />
          <div className="tech">
            <div className="tech-header">
              <span className="tech-title">Deck Tech</span>
            </div>
            <div className="tech-body">
              <CardSetTech 
                disabled={this.isDisabled()}
                initializing={this.isLoading()}
                cards={allCards} 
                updateCardMetadataFn={this.updateCardMetadata}
              />
            </div>
          </div>
        </div>
        <div className="support">
          <PatreonButton />
          <KoFiButton />
        </div>
        <div className="contact">
          <span>Feedback:</span> {/* eslint-disable-line */} <a 
              title="@SealedDeckTech"
              className="icon"
              href="https://twitter.com/SealedDeckTech"
              target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faTwitterSquare} size="lg" />
          </a> <a 
              className="icon"
              href="mailto:dave@sealeddeck.tech?subject=I've got an Idea/Problem/Question">
            <FontAwesomeIcon icon={faEnvelopeSquare} size="lg" />
          </a> | <button
              className="about link-button"
              onClick={this.showAboutModal}>
          About</button>
        </div>
      </div>);
  }

  renderRecentPools() {
    // render only the 4 most recent pool states
    const recent = this.recentPoolStates().slice(0, 4);
    if (recent && recent.length) {
      return <React.Fragment>
        <hr/>
        <div className="recent-pools">
          <div>Recently Published</div>
          { recent.map(this.renderRecentPoolState) }
        </div>
      </React.Fragment>
    }
    else return <React.Fragment></React.Fragment>;
  }

  /**
   * @param {Object} recentPoolState the attributes of a recently published pool state to render:
   * ```
     {
       poolId: ...
       tierlistId: ...
       colors: ...
       set: "xxx"
       tierlist: "???"
     }
     ```
   * @returns the rendered element
   */
  renderRecentPoolState = (recentPoolState) => {
    const { poolId, tierlistId, set, colors } = recentPoolState;

    // the pool state ID is either the poolId or tierlistId
    const poolStateId = poolId ? poolId : tierlistId;

    // render the set using KeyRune
    const renderSet = set ? <i className={`ss ss-${set.toLowerCase()} ss-fw`}></i> : <></>;
    // render a tier list icon
    const renderTierList = tierlistId ? <FontAwesomeIcon className="tierlist" icon={faListOl} /> : <></>;
    // render images for deck colors
    const renderColors = <>{ colors.map(symbol => getColorElement(symbol)) }</>;
    const renderSymbols = tierlistId ? <>{renderSet}{renderTierList}</> : <>{renderColors}{renderSet}</>;

    // set the 'id-small' class if more than five icons to display
    const numIcons = colors.length + (set ? 1 : 0);
    const renderPoolId = (numIcons > 5) ?
      <span className={"id-small"}>{poolStateId}</span> : <span>{poolStateId}</span>;

    // construct the link elements
    let renderLink;
    const urlParams = this.extractUrlParams();
    if (urlParams.pool_id === poolStateId || urlParams.tier_list_pool_id === poolStateId) {
      // distinguish a reference to the current pool state (no href)
      renderLink = <span 
          className="current"
          title="This is the currently loaded pool">
        {renderPoolId}&nbsp;{renderSymbols}
      </span>;
    }
    else {
      // regular link to the pool
      const url = tierlistId ? `/tierlists/${tierlistId}` : set ? `/sets/${set}/${poolId}` : `/${poolId}`;
      renderLink = <a 
          href={url}
        >
          {renderPoolId}&nbsp;{renderSymbols}
        </a>;
    }

    return <div className="recent-pool-id" key={"recent-"+poolStateId}>{renderLink}</div>;
  }

  /**
   * Add the provided CardFilter to the array of active cardfilters.
   * 
   * @param {CardFilter} filter 
   */
  addFilter = (filter) => {
    this.setState(prevState => 
      ({ cardfilters: prevState.cardfilters.concat(filter) }),
      // then save the changes locally
      this.savePoolStateLocally);
  }
  
  /**
   * Remove an active CardFilter with the specified type and value
   * 
   * @param {FilterType} type 
   * @param {string} value optional
   */
  removeFilter = (type, value) => {
    this.setState(prevState => {
        // keep those filters that do not match the filter type and value
        prevState.cardfilters = prevState.cardfilters.filter(f => !f.matches(type, value));
        return prevState;
      },
      // then save the changes locally
      this.savePoolStateLocally);
  }

  /**
   * @param {boolean} enabled true to enable filtering for all cards (deck/tier list), false to disable
   */
  activateFilterAll = (enabled) => this.setState({ isFilterAllActive: enabled }, this.savePoolStateLocally);

  isSetCardPool = () => this.cardpool instanceof SetCardPool;

  isTierListCardPool = () => this.cardpool instanceof TierListCardPool;

  /*****************************************************************************
   * URL Navigation
   */
  
  popState = () => {
    this.setState({ status: Status.Loading });
    // When writing functions that process popstate event it is important to take into account 
    // that properties like `window.location` will already reflect the state change (if it affected
    // the current URL), but `document` [and `this.props.history.location`] might still not.
    // ...
    // A zero-delay `setTimeout()` method call should be used to effectively put its inner callback 
    // function that does the processing at the end of the browser event loop.
    // 
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event#the_history_stack
    setTimeout(this.loadState, 0);
    // OR (force a (slower) reload)
    //window.location.reload();
  }

  loadState = () => {
    // set status to Loading to disable components
    this.resetState(Status.Loading);
    
    // always load normally from the URL
    this.loadStateFromURL()
      .catch(error => {
        console.error("Unable to load card pool state.", error);
        this.setState( { status: Status.Error } );
      });
  }

  /**
   * Load pool state based on the URL, checking both local and remote state
   */
  loadStateFromURL() {
    const urlParams = this.extractUrlParams();
    const isInvalidUrl = !this.props.match.isExact;
    const sessionId = this.extractSessionId(urlParams);

    // Determine whether to restore saved state or keep the empty state
    let loadingPromise;
    if (isInvalidUrl) {
      loadingPromise = Promise.reject(
        new Error("Unexpected path in URL. Not loading saved state."));
      // don't bother clearing state, Pool should not display cards
      //if (!this.isError()) {
      //  // reset to initial state with Error
      //  this.resetState(Status.Error);
      //}
    }
    else if (this.savedSessionState(sessionId)) {
      // load the saved state from the session (if it exists)
      loadingPromise = this.loadPoolStateFromSession(sessionId);
    }
    else if (urlParams.set_code) {
      // load a full set when `set_code` is specified
      loadingPromise = this.loadSetCardPool(urlParams.set_code, urlParams.pool_id);
    }
    else if (urlParams.tier_list_pool_id) {
      // expect a published tier list to have all cards, just load the pool
      loadingPromise = this.loadTierListCardPoolRemotely(urlParams.tier_list_pool_id); 
    }
    else if (urlParams.tier_list_set_code) {
      // load the tier list for a full set
      loadingPromise = this.loadTierListCardPool(urlParams.tier_list_set_code); 
    }
    else if (urlParams.pool_id) {
      // retrieve and load the state for the specified pool ID
      loadingPromise = this.loadPoolStateRemotely(urlParams.pool_id)
        .catch(error =>  {
          // don't set Status.Error, we are going to try and load as a DeckFile
          console.warn("Could not load a pool with id '" + urlParams.pool_id + "' -", error.message);
          // maybe this was a deck file ID?
          console.log("Try loading a deck file with id '" + urlParams.pool_id + "'");
          return this.loadDeckFileRemotely(urlParams.pool_id);
        });
    }
    else if (urlParams.seventeenlands_id) {
      // retrieve and load the state from 17lands data
      loadingPromise = this.loadPoolStateFrom17lands(urlParams.seventeenlands_id, urlParams.deck_id);
    }
    else if (urlParams.seventeenlands_tier_list_id) {
      loadingPromise = this.loadTierListFrom17lands(urlParams.seventeenlands_tier_list_id);
    }
    // deprecated path; keeping legacy code to support any lingering locally saved data
    else if (this.savedLocalState()) {
      // restore last locally-saved state
      loadingPromise = this.loadPoolStateLocally();
    }
    else {
      // resolve with existing cardpool and Empty app state
      loadingPromise = Promise.resolve({ 
        cardpool: this.cardpool, 
        appState: { status: Status.Empty }
      });
    }

    // once all loading is complete, restore the application state
    return loadingPromise
      .then(({cardpool, appState}) => this.restoreAppState(cardpool, appState));
  }

  /**
   * @returns a Promise for the loaded {@link CardPool} and an application state object:
   * ```
     { cardpool: ..., appState: {...} }
     ```
   */
  async loadPoolStateFromSession(sessionId) {
    // not necessary; only reached from initial loadState()
    //this.resetState(Status.Loading);
    
    // restore pool state and do *not* change Status (it should be restored)
    const sessionState = this.savedSessionState(sessionId);
    if (sessionState) {
      console.info("Restoring locally saved session state:", sessionId);

      // initialize the correct type of CardPool
      const urlParams = this.extractUrlParams();
      let cardpool;
      if (urlParams.set_code) {
        // restore the session state via a SetCardPool (expect set cards in sideboard)
        cardpool = new SetCardPool(sessionState);
      }
      else if (urlParams.tier_list_set_code || urlParams.tier_list_pool_id
          || urlParams.seventeenlands_tier_list_id) {
        // restore the session state via a TierListCardPool (expect set cards in sideboard)
        cardpool = new TierListCardPool(sessionState);
      }
      else {
        cardpool = new CardPool(sessionState);
      }

      // reconstruct the appState properties to restore
      const restoreState = {};

      // deserialize the card filters from JSON
      restoreState.isFilterAllActive = sessionState.isFilterAllActive;
      if (sessionState.cardfilters) {
        restoreState.cardfilters  = sessionState.cardfilters.map(CardFilter.fromJSON);
      }

      // deserialize the JSON `currentId` (if any) to the correct SourceID object
      if (sessionState.currentId) {
        restoreState.currentId = SourceID.fromJSON(sessionState.currentId);
      }

      // when loading from a saved local session, set to Changed unless we know it was Un/Published
      if (!Status.Published.matches(sessionState.status) &&
          !Status.Unpublished.matches(sessionState.status)) {
        // don't know if we can revert, but don't block it (Unpublished) and allow Publishing
        restoreState.status = Status.Changed;
      }
      else {
        // expect that this should be ehter Published or Unpublished
        restoreState.status = sessionState.status;
      }

      return cardpool.fetchMissingBestCards()
        .then(cardpool => ({ cardpool, appState: restoreState }));
    }
    else {
      return Promise.reject(new Error(`No saved session state to restore for '${sessionId}'`));
    }
  }

  /**
   * @returns a Promise for the loaded {@link CardPool} and an application state object:
   * ```
     { cardpool: ..., appState: {...} }
     ```
   */
  async loadPoolStateRemotely(poolId) {
    // When complete return a new cardpool and retrieved pool state
    const cardpoolAndStatePromise = RemoteStorage.readPoolState(poolId)
      .then(poolState => ({ cardpool: new CardPool(poolState), poolState }));

    // Fetch any missing card data
    const fetchPromise =  cardpoolAndStatePromise
      .then(({ cardpool, poolState }) => {
        return cardpool.fetchMissingBestCards().then(
          cardpool => ({ cardpool, poolState }));
      });
    
    // Also, immediately update the state by restoring the cardpool to display placeholder 
    // cards as visual indication of loading progress without waiting on card data.
    const placeholderPromise = cardpoolAndStatePromise
      .then(({cardpool}) => this.restoreAppState(cardpool));

    return Promise.all([fetchPromise, placeholderPromise])
      .then(([{cardpool, poolState}, _]) => {
        // construct the pool state to restore
        const restoreState = {...poolState};
        // clear any card filters when restoring a remotely saved pool
        // (otherwise `restoreAppState` will restore them)
        delete restoreState.cardfilters;
        delete restoreState.isFilterAllActive;
        // set the current source ID to this remote Pool State ID (in case we re-publish)
        restoreState.currentId = new RemotePoolStateID(poolId);
        // the restored state was remotely saved; set the status 
        // to Published to disable the Publish button
        restoreState.status = Status.Published;
        return {cardpool, appState: restoreState};
      });
  }

  /**
   * @returns a Promise for the loaded {@link CardPool} and an application state object:
   * ```
     { cardpool: ..., appState: {...} }
     ```
   */
  async loadDeckFileRemotely(remoteDeckFileId) {
    // read the deck file from storage and parse the content into a CardPool
    const cardpoolPromise =  RemoteStorage.readDeckFile(remoteDeckFileId)
      .then(this.parseDeckContent);

    // fetch card data for all new cards, starting immediately
    const fetchPromise = cardpoolPromise.then(cardpool => cardpool.fetchAllBestCards());
    // Also, immediately update the state with a spread cardpool to display placeholder 
    // cards as visual indication of loading progress without waiting on card data.
    const placeholderPromise = cardpoolPromise.then(this.spreadCards);

    // when card data is loaded, restore the application state
    // (but make sure this also happens after the placeholder state finishes loading)
    return Promise.all([fetchPromise, placeholderPromise])
      .then(([cardpool, _]) => {
        const restoreState = {
          // the restored state was loaded from remotely storage, so it is already Published
          status: Status.Published,
          // store the loaded DeckFile object ID in the local state
          currentId: new RemoteDeckFileID(remoteDeckFileId)
        }
        // sort the cardpool and restore
        return { cardpool: cardpool.defaultSort(), appState: restoreState };
      })
      .catch(error =>  {
        console.warn("Could not retrieve a deck with id '" + remoteDeckFileId + "' -", error.message);
        throw error;
      });
  }

  /**
   * @param {String} setCode the set code
   * @param {String} poolId  an optional pool ID to load from {@link RemoteStorage}
   * @returns a Promise for the loaded {@link CardPool} and an application state object:
   * ```
     { cardpool: ..., appState: {...} }
     ```
   */
  async loadSetCardPool(setCode, poolId) {
    // if a `poolId` is provided, remotely read the pool state first
    const cardpoolPromise = poolId ? RemoteStorage.readPoolState(poolId) : Promise.resolve();

    // initialize a SetCardPool, which returns a Promise (NOTE: it fetches card data)
    return cardpoolPromise.then(poolState => 
        // this chains a new Promise to initialize the SetCardPool;
        // to show placeholder cards, you would need to create a temporary CardPool 
        // containing (how many cards?) and initialize it with `App.spreadCards()`
        SetCardPool.initialize(setCode, poolState))
      .then(cardpool => cardpool.fetchMissingBestCards())
      .then(cardpool => ({ 
        cardpool, 
        // return an appState.status according to whether or not this was a published pool
        appState: { 
          // set the current source ID to this remote Pool State ID
          currentId: new RemotePoolStateID(poolId),
          status: poolId ? Status.Published : Status.Empty
        } 
      }));
  }

  /**
   * @param {String} setCode the set code
   * @returns a Promise for the loaded {@link CardPool} and an application state object:
   * ```
     { cardpool: ..., appState: {...} }
     ```
   */
  async loadTierListCardPool(setCode) {
    // initialize a SetCardPool, which returns a Promise (NOTE: it fetches card data)
    return TierListCardPool.initialize(setCode)
      .then(cardpool => ({ 
        cardpool, 
        // return an appState.status according to whether or not this was a published pool
        appState: { status: Status.Unpublished } 
      }));
  }

  async loadTierListCardPoolRemotely(tierlistId) {
    // if a `poolId` is provided, remotely read the pool state first
    const cardpoolPromise = RemoteStorage.readTierList(tierlistId)
      .then(poolState => new TierListCardPool(poolState));

    // fetch card data for all new cards, starting immediately
    const fetchPromise = cardpoolPromise.then(cardpool => cardpool.fetchMissingBestCards());

    // Also, immediately update the state by restoring the cardpool to display placeholder 
    // cards as visual indication of loading progress without waiting on card data.
    const placeholderPromise = cardpoolPromise.then(this.restoreAppState);

    return Promise.all([fetchPromise, placeholderPromise])
      .then(([cardpool, _]) => {
        // construct app state information to restore
        const restoreState = {
          // set the current source ID to this remote Tier List ID (in case we re-publish)
          currentId: new RemoteTierListID(tierlistId),
          // the restored state was remotely saved; set the status 
          // to Published to disable the Publish button
          status: Status.Published
        };

        return { cardpool, appState: restoreState };
      });
  }

  /**
   * @returns a Promise for the loaded {@link CardPool} and an application state object:
   * ```
     { cardpool: ..., appState: {...} }
     ```
   */
  async loadPoolStateFrom17lands(seventeenlandsId, deckId) {
    // Example URLs: 
    //  https://www.17lands.com/pool/c2413f72f9474a3880699b60d5f0f419.txt
    //  https://www.17lands.com/deck/c2413f72f9474a3880699b60d5f0f419/0.txt
    const poolUrl = (deckId) ?
      // is this a deck?
      `https://www.17lands.com/deck/${seventeenlandsId}/${deckId}.txt`
      // otherwise, get the whole pool
      : `https://www.17lands.com/pool/${seventeenlandsId}.txt`
    
    const cardpoolPromise = fetch(poolUrl, {
        method: 'get',
        headers: {
          'Accept': 'text/plain'
        }
      })
      .then(response => response.text())
      //TODO save 17lands deck content remotely?
      .then(this.parseDeckContent);

    // fetch card data for all new cards, starting immediately
    const fetchPromise = cardpoolPromise.then(cardpool => cardpool.fetchAllBestCards());
    // Also, immediately update the state with a spread cardpool to display placeholder 
    // cards as visual indication of loading progress without waiting on card data.
    const placeholderPromise = cardpoolPromise.then(this.spreadCards);

    // when card data is loaded, sort the cardpool and restore the application state
    // (but make sure this also happens after the placeholder state finishes loading)
    return Promise.all([fetchPromise, placeholderPromise])
      .then(([cardpool, _]) =>
        ({
          cardpool: cardpool.defaultSort(), 
          appState: { 
            status: Status.Unpublished,
            // set the current source ID to this external 17Lands Pool ID (in case we re-publish)
            currentId: new SeventeenLandsPoolID(seventeenlandsId, deckId)
          }
        }));
  }

  async loadTierListFrom17lands(seventeenlands_tier_list_id) {
    // Example URLs: 
    //  https://www.17lands.com/tier_list/075a8bf11c5f40a0b9cffa82e67a51e2
    //  https://www.17lands.com/card_tiers/data/075a8bf11c5f40a0b9cffa82e67a51e2
    return SeventeenLands.cardTiers(seventeenlands_tier_list_id)
      .then(tierListCards => {
        // partition into cards that are 'TBD' tier (sideboard) and those with tier ratings
        const [tbd, tiered] = tierListCards.reduce((acc, card) => {
          // only preserve the fields that we expect to use
          const cleanCard = {
            name: card.name, 
            [RATING_TIER_PROPERTY]: card.tier, 
            set: card.expansion.toLowerCase(),
            flags: card.flags
          };
          acc[card.tier === "TBD" ? 0 : 1].push(cleanCard);
          return acc;
        }, [[], []]);

        const tierlistCardPool = new TierListCardPool();
         // this adds the cards to a new prepended column
        tierlistCardPool.addCards({ sideboard: tbd, deck: tiered });

        // Immediately update the state with a spread cardpool to display placeholder 
        // cards as visual indication of loading progress without waiting on card data.
        tierlistCardPool.sortSideboardBy((cards, splitCards) => 
          spreadCardsAcrossColumns(/* numColumns = */ 10, cards, splitCards));
        tierlistCardPool.sortDeckBy(cardsToTierListColumns);
        // Update the state with the spread cardpool
        const placeholderPromise = this.restoreAppState(tierlistCardPool);

        // after the "best" card data is loaded, sort the cardpool and restore the application state
        // (but make sure this also happens after the placeholder state finishes loading)
        return Promise.all([tierlistCardPool.fetchAllBestCards(), placeholderPromise])
      })
      .then(([cardpool, _]) => {
        // try to determine the tier list card set after fetching card data
        // NOTE: the card set should be provided by 17Lands, but after fetch just in case...
        const cardSets = SeventeenLands.sanitizeAllCardSets(cardpool.allCards());
        cardpool.set = (cardSets.length >= 1) ? cardSets[0] : undefined;
        
        // sort the sideboard normally (by color *identity*)
        cardpool.sortSideboardBy(cardsToColumnsByColorIdentity);
        // sort the deck into ordered tier list columns
        cardpool.sortDeckBy(cardsToTierListColumns);
        // trim empty columns (e.g., the empty `Unknown` card column at the end)
        cardpool.trimEmptyColumns();

        // drop card tier data (which is otherwise visible) after sorting;
        // using `allCardMetadata` to efficiently get each `cardId` (vs `allCards`)
        const cleanMetadata = cardpool.allCardMetadata().map(metadata => 
          ({ cardId: metadata.cardId, [RATING_TIER_PROPERTY]: undefined  }));
        cardpool.updateCardMetadata(cleanMetadata);

        // set status to Status.Changed so that we can publish and/or revert
        return { cardpool, appState: { 
          status: Status.Changed,
          // set the current source ID to this external 17Lands Tier List ID (in case we re-publish)
          currentId: new SeventeenLandsTierListID(seventeenlands_tier_list_id)
        }};
      });
  }

  /**
   * @deprecated
   * @returns a Promise for the loaded {@link CardPool} and an application state object:
   * ```
     { cardpool: ..., appState: {...} }
     ```
   */
  async loadPoolStateLocally() {
    // not necessary; only reached from initial loadState()
    //this.resetState(Status.Loading);
    
    const localState = this.savedLocalState();
    if (localState) {
      console.info("Restoring locally saved state");
      // local state is always a regular CardPool
      const cardpool = new CardPool(localState);
      // restore pool state with status Unpublished (local state is always unpublished)
      const restoreState = { ...localState, status: Status.Unpublished };

      // refresh any missing cards in the card pool
      return cardpool.fetchMissingBestCards()
        .then(cardpool => ({ cardpool, appState: restoreState }));
    }
    else {
      return Promise.reject(new Error("No saved local state to restore"));
    }
  }

  /**
   * Restore the application state with the given cardpool (to be stored in `this.cardpool`)
   * and from any given JSON application state to update/render the UI (via `setState`).
   * 
   * @param {Object} cardpool 
   * @param {Object} appState 
   * @returns the Promise that restores the pool state
   */
  restoreAppState = (cardpool, appState) => {
    // store the cardpool locally (but not yet in state)
    this.cardpool = cardpool;
    // get the loaded cardpool state to restore
    const cardpoolState = this.cardpool.toJsonState();

    let restoreState;
    if (appState) {
      // if appState is provided, merge it with the CardPool state
      restoreState = { ...appState, ...cardpoolState };
    }
    else {
      restoreState = cardpoolState;
    }

    // update the state and, when complete, save locally
    return new Promise(resolveFn => this.setState(restoreState, resolveFn))
      .then(() => this.savePoolStateLocally());
  }

  /**
   * Match the URL and determine if the URL is 'exact'
   * (NOT exact if it contains more than just the expected params).
   * 
   * Returns the URL parameters if there is an exact match.
   */
  extractUrlParams() {
    if (this.props.match.isExact) {
      return this.props.match.params;
    }
    return {};
  }

  extractSessionId(urlParams) {
    if (urlParams.seventeenlands_id) {
      const pool = `17lands-${urlParams.seventeenlands_id}`;
      // append an optional deck ID?
      return urlParams.deck_id ? `${pool}-${urlParams.deck_id}` : pool;
    }
    else if (urlParams.seventeenlands_tier_list_id) {
      return `${SESSION_STORAGE_TIERLISTS_PREFIX}17lands-${urlParams.seventeenlands_tier_list_id}`;
    }
    else if (urlParams.set_code) {
      const setId = `${SESSION_STORAGE_SET_PREFIX}${urlParams.set_code}`;
      if (urlParams.pool_id) return `${setId}-${urlParams.pool_id}`
      return setId;
    }
    else if (urlParams.tier_list_set_code && urlParams.tier_list_pool_id) {
      return `${SESSION_STORAGE_TIERLISTS_PREFIX}${urlParams.tier_list_set_code}-${urlParams.tier_list_pool_id}`;
    }
    else if (urlParams.tier_list_set_code) {
      return `${SESSION_STORAGE_TIERLISTS_PREFIX}${urlParams.tier_list_set_code}`;
    }
    else if (urlParams.tier_list_pool_id) {
      return `${SESSION_STORAGE_TIERLISTS_PREFIX}${urlParams.tier_list_pool_id}`;
    }
    else {
      // undefined if no pool ID
      return urlParams.pool_id;
    }
  }

  /**
   * End URL navigation
   *****************************************************************************/

  /**
   * Use cardId of each given card to update the current card state and update the view state.
   * 
   * @param {Object[]} cards 
   * @returns {Promise}
   */
  updateCardMetadata = (cards) => {
    this.cardpool.updateCardMetadata(cards);
    return this.onCardPoolChanged();
  }
  
  /**
   * Update the view state based on the local cardpool model and then update the status 
   * and save changes locally.
   * 
   * If there is a session ID, set the status to Status.Changed (local changes that 
   * can be reverted). This includes published pools and 17lands data with changes, 
   * but not the base url.
   * 
   * Otherwise set status to Status.Unpublished (changes that cannot be reverted, 
   * but that can still be published).
   * 
   * If the current status is Status.Error then no updates are made.
   */
  onCardPoolChanged = () => {
    if (this.isError()) {
      console.warn("Skipping pool update while in error state");
      return;
    }

    const newState = this.cardpool.toJsonState();

    const urlParams = this.extractUrlParams();
    const sessionId = this.extractSessionId(urlParams);
    // for a published pool or 17lands pool/deck, update to Status.Changed
    newState.status = sessionId ? Status.Changed : Status.Unpublished;

    return new Promise(resolveFn => this.setState(newState, resolveFn))
      .then(this.savePoolStateLocally);
   }

  /*****************************************************************************
   * Local and remote state persistence
   */

  savedSessionState = (sessionId) => {
    const sessionState = sessionStorage.getItem(sessionId);
    return sessionState ? JSON.parse(sessionState) : sessionState;
  }

  /**
   * @deprecated
   * @returns the locally stored JSON state; or undefined.
   */
  savedLocalState = () => {
    const localState = localStorage.getItem('state');
    return localState ? JSON.parse(localState) : localState;
  }

  clearLocalState = () => localStorage.removeItem('state');

  incrementLocalPublishCount = () => {
    // retrieve publish count
    let localPublishCount = parseInt(localStorage.getItem('publish-count'));
    // if no `publish-count` check for legacy `publishCount`
    localPublishCount = parseInt(localStorage.getItem('publishCount'));
    if (!localPublishCount) localPublishCount = 0;
    // increment count
    localPublishCount += 1;
    try {
      // save updated count
      localStorage.setItem('publish-count', localPublishCount);
      // once saved successfully, clear the legacy `publishCount` item
      localStorage.removeItem('publishCount');
    }
    catch (error) {
      console.warn("Unable to save to session storage.", error);
    }
    return localPublishCount;
  }

  /**
   * Save the attributes for a recently published pool state; either `poolId` or `tierlistId` are required.
   * @param {CardPool} cardpool the cardpool being published
   * @param {String} poolId 
   * @param {String} tierlistId 
   * @param {String} set (optional) set for the published pool state
   */
  saveRecentPoolState = (cardpool, poolId, tierlistId, set) => {
    // calculate deck card colors
    let deckCardIds = cardpool.deckCardIds();
    // convert card IDs to Cards and get card colors
    const deckCards = deckCardIds.map(cardpool.card);
    const colorsSorted = getColorsSortedByPips(deckCards);
    let colors = colorsSorted
      // map to color; 4 or fewer cards is a splash color (lower case)
      .map(c => (c.count <= 4) ? c.color.toLowerCase() : c.color.toUpperCase())
      // don't include colorless as a color (splash or otherwise)
      .filter(color => color.toUpperCase() !== "C");
    // explicitly set 'C' for colorless; empty colors array when no cards in deck
    if (!colors.length)  {
      colors = (deckCards.length) ? ["C"] : [];
    }

    // get locally-saved recent pool states and prepend the provided PoolState ID
    const recentPoolStates = this.recentPoolStates();

    // create new object of provided pool attributes, plus the calculated card pool colors
    const current = { poolId, tierlistId, set, colors };
    recentPoolStates.unshift(current);
    // store the 20 most recent PoolState IDs
    const newRecents = recentPoolStates.slice(0, 20);
    try {
      localStorage.setItem('recent', JSON.stringify(newRecents));
    }
    catch (error) {
      console.warn("Unable to save recent pools to local storage.", error);
    }
  }

  recentPoolStates() {
    // retrieve the most recent pool states
    const recentPoolStates = JSON.parse(localStorage.getItem('recent'));
    if (!recentPoolStates) {
      return [];
    }
    // if necessary, convert old array-based recent state format (legacy storage)
    return recentPoolStates.map(recentState => {
      if (Array.isArray(recentState)) {
        const [poolId, colors] = recentState; 
        return { poolId: poolId, colors: colors };
      }
      else return recentState;
    });
  }

  /**
   * Save the state to local (temporary) session storage if there is a session ID 
   * (local changes that can be reverted). This includes published pools and 17lands 
   * data with changes, but not the base url.
   * 
   * Otherwise do nothing.
   */
  savePoolStateLocally = () => {
    const urlParams = this.extractUrlParams();
    const sessionId = this.extractSessionId(urlParams);
    if (sessionId) {
      // combine local state with the CardPool export
      const poolExport = this.cardpool.export(true);
      const persistJson = JSON.stringify({ ...this.state, ...poolExport });

      try {
        // if a published or 17lands pool/deck (with a session ID), save to session storage
        sessionStorage.setItem(sessionId, persistJson);
      }
      catch (error) {
        console.warn("Unable to save to session storage.", error);
        const success = this.maybeClearSetsFromLocalPoolState();
        // try again?
        if (success) this.savePoolStateLocally();
      }
    }
    else {
      console.debug("No active session to save state");
    }
  }

  maybeClearSetsFromLocalPoolState = () => {
    const deleted = Object.keys(sessionStorage).map(key => {
      if (key.startsWith(SESSION_STORAGE_SET_PREFIX) || key.startsWith(SESSION_STORAGE_TIERLISTS_PREFIX)) {
        console.info("Deleting local session state for", key);
        sessionStorage.removeItem(key);
        return true;
      }
      return false;
    });
    return deleted.some(d => d);
  }
  
  savePoolStateRemotely = () => {
    return RemoteStorage.savePoolState(this.cardpool.export(), this.state.currentId);
  }

  saveTierListRemotely = () => {
    return RemoteStorage.saveTierList(this.cardpool.export(), this.state.currentId, this.cardpool.set);
  }

  /*
   * End state persistence
   *****************************************************************************/

  handlePublish = (event) => {
    // call window.open before the async call so that the browser doesn't block the pop-up
    const windowRef = window.open();
    // get the set code of the current pool (if any) from the URL
    const set_code = this.extractUrlParams().set_code;

    return this.publishCurrentPool() 
      .then(poolStateId => this.showPublishedPool(windowRef, poolStateId, set_code))
      .catch(err => {
        //TODO setState 'error' but still show deck/pool
        this.setState({ status: Status.Error });
        // close the opened window on error
        windowRef.close();
        console.error('Error while saving pool state remotely', err);
      });
  }

  publishCurrentPool = () => {
    // increment and track the publish count
    //TODO Modal dialog when count reaches a certain mulitple?
    this.incrementLocalPublishCount();

    // set status to Publishing (disable all controls and show loading spinner)
    const initialStatus = this.state.status;
    this.setState({ status: Status.Publishing });

    if (this.isTierListCardPool()) {
      return this.saveTierListRemotely()
        .then(tierListId => {
          // store the tier list ID and tier list set code (if known) to recent links
          // `this.cardpool.set` might be undefined
          this.saveRecentPoolState(this.cardpool, undefined, tierListId, this.cardpool.set);

          // restore the initial status; otherwise stuck in `Publishing`
          this.setState({ status: initialStatus });
          return tierListId;
        });
    }
    else {
      return this.savePoolStateRemotely()
        .then(poolStateId => {
          if (this.isSetCardPool()) {
            // store the pool state ID and set code to recent links
            const urlParams = this.extractUrlParams();
            this.saveRecentPoolState(this.cardpool, poolStateId, undefined, urlParams.set_code);
          }
          else {
            // store the pool state ID and set code to recent links
            this.saveRecentPoolState(this.cardpool, poolStateId);
          }

          // if succesfully published from the base URL, delete local state (if any); 
          // legacy code to remove any lingering data
          if (this.props.location.pathname === "/") {
            this.clearLocalState();
          }
          // restore the initial status; otherwise stuck in `Publishing`
          this.setState({ status: initialStatus });
          return poolStateId;
        });
    }
  }

  /**
   * Show the published version of the current card pool in the specified window.
   * 
   * @param {*} windowRef 
   * @param {String} poolStateId 
   * @param {String} set Optional set for the current card pool
   */
  showPublishedPool(windowRef, poolStateId, set) {
    // update the location after the async process resolves
    if (this.isSetCardPool() && set) {
      windowRef.location.assign(`/sets/${set}/${poolStateId}`);
    }
    else if (this.isTierListCardPool()) {
      // always drop the set code now that we have a `poolStateId`
      // (and the TierListCardPool includes sideboard card data)
      windowRef.location.assign(`/tierlists/${poolStateId}`);
    }
    else {
      windowRef.location.assign("/" + poolStateId);
    }
  }

  /**
   * Update the current card pool with the provided pool content.
   * 
   * @param {*} content 
   */
  handleUpdate = (content) => {
    this.closeModal();

    // set status to Loading to disable components
    this.resetState(Status.Loading);

    // read the new card pool content
    const newCardData = PoolReader.read(content);
    // clone the cardpool so that the current pool doesn't get modified 
    // (and saved to local/session state with the added cards)
    const clonedCardPool = this.cardpool.clone();
    // add the new card data to the cloned cardpool
    clonedCardPool.addCards(newCardData);
    //Note: the 'best' version for the added cards will be based on the entire card pool, not just
    // those cards that are being added. This might fetch the wrong version, if adding a booster 
    // from a different set (that also has the same card name; seems unlikely) but is more likely
    // to be the desired behavior when adding new cards.  This won't happen if the set is specified.
    return clonedCardPool.fetchMissingBestCards()
      .then(this.saveAndLoadCardPoolRemotely)
      .catch(error => {
        console.error("Could not load/update the pasted pool", error)
        this.setState( { status: Status.Error } );
      });
  }

  /**
   * Create a new card pool with the provided pool content.
   * 
   * @param {*} content 
   */
  handlePaste = (content) => {
    this.closeModal();

    // set status to Loading to disable components
    this.resetState(Status.Loading);

    // process deck content
    const cardpool = this.parseDeckContent(content);
    cardpool.fetchAllBestCards()
      // automatically sort and save the card pool
      .then(cardpool => this.saveAndLoadCardPoolRemotely(cardpool.defaultSort()))
      .catch(error => {
        console.error("Could not load the pasted pool", error)
        this.setState( { status: Status.Error } );
      });
  }

  /**
   * Remotely save the provided {@link CardPool} and then navigate to that URL to 
   * load it as the new pool state.
   * 
   * Note: navigate using `history.push` from `react-router-dom`.
   * 
   * @param {CardPool} cardpool 
   */
  saveAndLoadCardPoolRemotely = (cardpool) => {
    // export the card pool state and save remotely
    RemoteStorage.savePoolState(cardpool.export())
      .then(poolStateId => {
        // delete local state (if any); legacy code to remove any lingering data
        this.clearLocalState();
        // store pool state ID to recent links (using the pasted cardpool)
        // NOTE: this is on Paste and Update, which should be disabled for Sets and Tier Lists
        this.saveRecentPoolState(cardpool, poolStateId);

        // after saving, change the current URL (using `history.push` from react-router props)
        // NOTE: because of this, `onpopstate` is/must be configured in `componentDidMount`
        this.props.history.push("/" + poolStateId);
        // we didn't navigate away, so explicitly restore the app state
        return this.restoreAppState(cardpool,
          /* appState = */ { stateId: new RemotePoolStateID(poolStateId), status: Status.Published });
      });
  }

  handleFile = (e) => {
    // set status to Loading to disable components
    this.resetState(Status.Loading);

    const file = e.target.files[0];
    console.info(file);
    // save file content
    RemoteStorage.saveDeckFile(file)
      .then(deckFileId =>
        // store the saved DeckFile object id in the local state
        this.setState({ currentId: new RemoteDeckFileID(deckFileId) }))
      .catch(err =>
        console.error('Error while saving file', err)
      );
    
    // start reading the file, wrapped in a Promise
    const cardpoolPromise = new Promise((resolveFn, rejectFn) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => { resolveFn(event.target.result) };
        fileReader.onerror = (err)  => { rejectFn(err) };
        fileReader.readAsText(file);
      })
      .then(cardData => {
        const cardpool = this.parseDeckContent(cardData);
        return cardpool.fetchAllBestCards();
      });

    // automatically save the sorted card pool remotely
    const savedPromise = cardpoolPromise.then(cardpool => 
      RemoteStorage.savePoolState(cardpool.defaultSort().export()));
    
    // clean up after the cardpool is saved
    Promise.all([cardpoolPromise, savedPromise])
      .then(([cardpool, poolStateId]) => {
        // delete local state (if any); legacy code to remove any lingering data
        this.clearLocalState();
        // store pool state ID to recent links (using the loaded cardpool)
        this.saveRecentPoolState(cardpool, poolStateId);
        // change current URL after saving
        window.location.href = "/" + poolStateId;
      })
      .catch(error => {
        console.error("Could not load pool from file", error);
        this.setState( { status: Status.Error } );
      });
  }

  /**
   * Reads the provided deck content and attempts to build a new card pool and fetch the card data.
   * @param {string} content 
   * @returns the loaded CardPool
   */
  parseDeckContent = (content) => {
    const cardData = PoolReader.read(content);
    const newCardPool = new CardPool();
    newCardPool.addCards(cardData);
    return newCardPool;
  }

  /**
   * Replace the card pool (sideboard and deck) with the provided card pool.
   * This will update the application state by spreading the cards across 
   * six columns in both the sideboard and deck.
   * 
   * @param {Object} cardpool the cardpool to load
   */
  spreadCards = (cardpool, numColumns = 6) => {
    // Define a "sort" function to distribute cards for each pool across `numColumns`
    const acrossSixColumns = (cards, splitCards) => 
      spreadCardsAcrossColumns(numColumns, cards, splitCards);

    cardpool.sortSideboardBy(acrossSixColumns);
    cardpool.sortDeckBy(acrossSixColumns);

    // Update the state with the spread cardpool
    return this.restoreAppState(cardpool);
  }

  revertState = () => {
    const urlParams = this.extractUrlParams();
    const sessionId = this.extractSessionId(urlParams);
    if (sessionId) {
      // set status to Loading to disable components
      this.resetState(Status.Loading)
      // remove any locally saved session state before reloading
      sessionStorage.removeItem(sessionId);

      // also remove locally saved 17Lands tier list data from session state 
      // (the tier list itself may have changed; revert will not use the cache)
      if (urlParams.seventeenlands_tier_list_id) {
        const session17landsRatings = SESSION_STORAGE_SEVENTEENLANDS_TIERLIST_PREFIX + 
          urlParams.seventeenlands_tier_list_id;
        sessionStorage.removeItem(session17landsRatings);
      }

      this.loadStateFromURL()
        .catch(error => {
          console.error("Could not restore pool", error)
          this.setState( { status: Status.Error } );
        });
    }
    else {
      console.warn("No state to restore.")
      // set the state to Unpublished to disable Revert
      this.setState( { status: Status.Unpublished } );
    }
  }

}

export default withRouter(App);
