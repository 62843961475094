import { isStandardBasicLand } from '../helpers/card.helpers';
import { groupCardsBySet } from '../helpers/card-pool.helpers';

import { BonusSheetSetCodes, RemasteredSetCodes, WUBRG } from '../constants';
import { SET_RATINGS as CHORD_SET_RATINGS } from './constants/chord-o-calls';
import { SET_RATINGS as LOL_SET_RATINGS} from './constants/lords-of-limited';

/**
 * Prefix used for storing 17Lands tier list data in session storage.
 */
export const SESSION_STORAGE_SEVENTEENLANDS_TIERLIST_PREFIX = '17lands-tiers-';

/**
 * Specify the direct and cached 17Lands URIs. The cache does not 
 * directly hit http://17lands.com but instead looking to the local 
 * API which is configured for Vercel to cache the responses.
 */
const SEVENTEENLANDS_URI = 'https://www.17lands.com'
const SEVENTEENLANDS_CACHE_URI = `${window.location.origin}/api/17lands`;
// for accessing the cache during development (i.e., localhost)
const SEVENTEENLANDS_CACHE_URI_DEV = 'https://sizzle.sealeddeck.tech/api/17lands';

const bent = require('bent')
const seventeenlandsGET = bent(SEVENTEENLANDS_URI, 'json', 200);
const seventeenlandsCardDataGET = bent(
    // use the dev version of our own cache API when in 'development' environment;
    // otherwise use the API corresponding to the environment (for Vercel Serverless Functions)
    ((process.env.NODE_ENV === 'development') ? 
      SEVENTEENLANDS_CACHE_URI_DEV : SEVENTEENLANDS_CACHE_URI) + '/card_ratings/data',
    'json', 200);

export default class SeventeenLands {

  /**
   * This will produce a sanitized, ordered array of card sets from the given set of cards,
   * such that the card data corresponding to those sets is likely to exist on 17Lands.
   * 
   * It will:
   *  - Ignore the set of any basic lands
   * 
   *  - Remove supplemental sets due to inclusion of, e.g., 'masterpiece' cards.
   *    For example, it will replace 'sta' (Strixhaven Mystical Archives) with 'stx' unless
   *    it already exists because these are considered the same set for card ratings.
   * 
   *  - Replace standard sets with remastered sets, when one is known to be available.
   *    For example, converting 'akh' and/or 'hou' to 'akr' (Amonkhet Remastered).
   * 
   *  - Remove standard sets ('snc') if the corresponding Alchemy set exists ('ysnc')
   * 
   * @param {Object[]} cards a full collection of cards to consider as a batch
   * @returns An array of the sanitized card sets, ordered by number of cards in that set.
   */
  static sanitizeAllCardSets(cards) {
    // filter out basic lands
    const cardsNonBasics = cards.filter(c => !isStandardBasicLand(c));
    // normalize the card sets, filtering out any undefined sets
    const normalizedNonBasics = cardsNonBasics.map(card => 
        ({ ...card, set: SeventeenLands.normalizeCardSet(card) })
      ).filter(card => !!card.set);

    // group cards by set and sort sets by the number of cards in group, largest first
    const normalizedCardSetGroups = groupCardsBySet(normalizedNonBasics);
    const normalizedCardSets = Object.keys(normalizedCardSetGroups)
      .sort((a, b) => normalizedCardSetGroups[b].length - normalizedCardSetGroups[a].length);

    // Remove any 'Special Guests' (SPG) and 'The List' (PLST) cards for any 17Lands stats
    const sanitizedSets = normalizedCardSets.filter(set => 
      set !== 'spg' && set !== 'plst');

    // special handling for Alchemy (Y-prefixed) card sets
    const alchemy = sanitizedSets.filter(set => 
      set.length > 3 && set.startsWith('y'));
    // if an alchemy set appears, remove the corresponding original set if it exists
    // (this is likely a mixed Alchemy pool for that set)
    alchemy.forEach((alchemySet, index) => {
      const originalSet = alchemySet.substring(1);
      const originalSetIndex = sanitizedSets.findIndex(set => set === originalSet);
      if (originalSetIndex >= 0) {
        sanitizedSets.splice(originalSetIndex, 1);
      }
    });
    
    return sanitizedSets;
  }

  /**
   * @param {Object} card the Scryfall card data from which to determine the 
   *                       corresponding card set with 17Lands data
   * @returns the normalized card set
   */
  static normalizeCardSet(card) {
    if (!card.set) return undefined;
    const set = card.set.toLowerCase();

    // Special handling for Arena-specific cards on "The List" which do not exist on Scryfall
    //  See https://mtg.fandom.com/wiki/The_List
    // Do this BEFORE any bonus/remastered set code normalization
    if (card.name === "Smuggler's Copter"
        || card.name === "Possibility Storm"
        || card.name === "Evolutionary Leap") {
      // Even though they would be expected as set 'plst' (The List) these cards were linked
      // to MKM and will be found with MKM for card tiers and ratings data on 17Lands.
      return 'mkm';
    }

    // replace bonus sheet set codes with standard set codes
    for (const [standardSetCode, bonusSetCodes] of Object.entries(BonusSheetSetCodes)) {
      if (bonusSetCodes.includes(set)) {
        return standardSetCode;
      }
    }

    // replacement set codes for specific target sets to match 17Lands data
    const ReplacementSetCodes = {
      // replace standard set codes with remastered set codes
      ...RemasteredSetCodes,
      // replace M3C with MH3: special handling for M3C eight Commander mythic rares from (Modern 
      // Horizon's 3 Commander) that can be found as Wild Cards in the MH3 Play Boosters
      mh3: [ "m3c" ]
    };
    for (const [replacementSetCode, targetSetCodes] of Object.entries(ReplacementSetCodes)) {
      if (targetSetCodes.includes(set)) {
        return replacementSetCode;
      }
    }

    return set;
  }
  
  static tierListUrl(tier_list_id) {
    return `${SEVENTEENLANDS_URI}/tier_list/${tier_list_id}`;
  }

  static async cardData(set, format = 'PremierDraft', colors = undefined) {
    console.info(`Retrieving 17Lands card data for [${set.toUpperCase()}] ${format}`);
    // hard-code start_date to avoid default of 120 days (1993-08-05 is Scryfall's release date for Alpha)
    let cardDataParams = `?expansion=${set.toUpperCase()}&format=${format}&start_date=1993-08-05`;
    if (colors) {
      // calculate the colors in WUBRG order
      const colorChars = [...colors].map(char => char.toUpperCase());
      const wubrgColors = WUBRG.filter(char => colorChars.includes(char));
      cardDataParams += `&colors=${wubrgColors.join('')}`
    }
    return seventeenlandsCardDataGET(cardDataParams);
  }

  static async cardTiersChordOCalls(set) {
    if (CHORD_SET_RATINGS.hasOwnProperty(set)) {
      return this.cardTiers(CHORD_SET_RATINGS[set].tier_list_id)
    }
    else {
      return Promise.reject(
        new Error(`No Chord_O_Calls 17Lands tier list ID configured for set [${set.toUpperCase()}]`,
          { set }));
    }
  }

  static async cardTiersLordsOfLimited(set) {
    if (LOL_SET_RATINGS.hasOwnProperty(set)) {
      return this.cardTiers(LOL_SET_RATINGS[set].tier_list_id)
    }
    else {
      return Promise.reject(
        new Error(`No Lords of Limited 17Lands tier list ID configured for set [${set.toUpperCase()}]`,
          { set }));
    }
  }

  static async cardTiers(tier_list_id) {
    if (!tier_list_id) {
      return Promise.reject(new Error('No 17Lands tier list ID provided'));
    }
    else {
      // first try to load the saved ratings for this set from the session (if it exists)
      const sessionKey = `${SESSION_STORAGE_SEVENTEENLANDS_TIERLIST_PREFIX}${tier_list_id}`
      const savedSetRatings = sessionStorage.getItem(sessionKey);
      if (savedSetRatings) {
        console.info(`Restoring 17Lands card tier data from session state '${sessionKey}'`);
        const json = JSON.parse(savedSetRatings);
        return Promise.resolve(json);
      }
      else {
        console.info(`No saved session for key '${sessionKey}'`);
        // check for remote data (17lands JSON data format)
        return this.cardTiersRemote(tier_list_id)
          .then((json) => SeventeenLands.saveToSession(json, sessionKey))
          .catch((error) => {
            return Promise.reject(
              new Error(`Unable to load 17Lands card tier data for ID '${tier_list_id}'.`,
                { tier_list_id, cause: error }));
          });
      }
    }
  }

  static async cardTiersRemote(tierListId) {
    // 17Lands JSON data URL differs from the web application view, for example:
    // https://www.17lands.com/tier_list/c2413f72f9474a3880699b60d5f0f419
    // https://www.17lands.com/card_tiers/data/c2413f72f9474a3880699b60d5f0f419
    const tiersUrl = `/card_tiers/data/${tierListId}`
    console.info(`Retrieving card tier data from 17lands ${tiersUrl}`);
    return seventeenlandsGET(tiersUrl);
  }

  /**
   * Try to save the provided JSON tier list to session storage.
   * 
   * @param {Object} json 
   * @param {String} sessionKey 
   * @returns the provided `json`
   */
  static saveToSession(json, sessionKey) {
    try {
      sessionStorage.setItem(sessionKey, JSON.stringify(json));
    }
    catch (error) {
      console.warn("Unable to save tier list data to session storage.", error);
    }
    return json; 
  }

}